import { datadogRum } from '@datadog/browser-rum';
import ENV from 'eh-utils/envs';

const AUTOMATION_TEST_INDICATOR =
  'automation-test-00e5ab9f664bcbc14140440cd7c0eeea';

const PINGDOM_USERS = ['status+uk@employmenthero.com'];

const getUTMSource = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('utm_source');
};

const getCurrentUserEmail = () => window.gon?.current_user_email;

const WHITELISTED_TRACKING_PATHS = [
  /^\/app\/v2\/organisations\/\d+\/dashboard/,
  /^\/app\/v2\/organisations\/\d+\/employee_files\/\d+/,
  /^\/memberships\/\d+/,
];

const isWhitelistedTrackingPath = () =>
  WHITELISTED_TRACKING_PATHS.some(regex =>
    regex.test(window.location.pathname)
  );

/*
 * Only run RUM for these use cases (to save credits using Datadog)
 * 1. Unauthenticated: utm_source matches a secret
 * 2. Authenticated:   dashboard, employee profile (old and new) for pingdom users
 */
const shouldRunDatadogRUM = () => {
  if (ENV.REACT_APP_ENABLED_DATADOG_RUM !== 'true') {
    return false;
  }

  const currentUserEmail = getCurrentUserEmail();
  if (currentUserEmail != null) {
    return (
      PINGDOM_USERS.includes(currentUserEmail) && isWhitelistedTrackingPath()
    );
  }
  return AUTOMATION_TEST_INDICATOR === getUTMSource();
};

const initDatadogRUM = () => {
  if (shouldRunDatadogRUM() === true) {
    datadogRum.init({
      site: 'datadoghq.com',
      applicationId: ENV.REACT_APP_DATADOG_RUM_APP_ID,
      clientToken: ENV.REACT_APP_DATADOG_PUBLIC_TOKEN,
      service: 'frontend-core/apps/hr-web-app',
      env: process.env.NODE_ENV,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      useSecureSessionCookie: true,
      trackSessionAcrossSubdomains: true,
      useCrossSiteSessionCookie: true,
      trackUserInteractions: false,
      trackResources: true,
      trackLongTasks: true,
      traceSampleRate: 0,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }
};

export { initDatadogRUM };
