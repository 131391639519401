import ReactDOM from 'react-dom';
import merge from 'lodash/fp/merge';

import { Intl, addLocaleData } from '@ehrocks/eh-redux-intl';
import enLocaleData from '@ehrocks/eh-redux-intl/locale-data/en';
import { combineReducers, createStore } from 'redux';

import './initializer/vitePolyfill';
import './initializer/globalCss';
import './initializer/envsRegistry';
import './initializer';
import { initDatadogRUM } from './initializer/datadogRUM';

import initSentry from './packages/eh-utils/sentry';
import { getLocaleConfigFromCdn } from './state/getLocaleConfig';
import FALLBACK_TRANSLATION from './packages/eh-locale/lang/en-fallback.json';

const LOCALE_CODE = {
  AU: 'en-AU',
  GB: 'en-GB',
  NZ: 'en-NZ',
};

const rootEntrypoint = document.getElementById('root');

const publicEntrypoint = document.getElementById('react-public');

const initApp = () => {
  initSentry(window.gon?.current_user_email);
  initDatadogRUM();

  if (publicEntrypoint) {
    import(
      /* webpackChunkName: "publicRoutes" */ './routes/publicRouteConfig'
    ).then(({ default: routeConfig }) => {
      import(/* webpackChunkName: "asyncApp" */ './App').then(
        ({ default: App }) => {
          ReactDOM.render(
            <App routeConfig={routeConfig} showNotiAsDefault />,
            publicEntrypoint
          );
        }
      );
    });
  } else if (rootEntrypoint) {
    import(/* webpackChunkName: "routes" */ './routes/routeConfig').then(
      ({ default: routeConfig }) => {
        import(/* webpackChunkName: "asyncApp" */ './App').then(
          ({ default: App }) => {
            ReactDOM.render(<App routeConfig={routeConfig} />, rootEntrypoint);
          }
        );
      }
    );
  } else {
    const injectRendering = () => {
      const hasLegacyReactApp = !!document.getElementById('react_app');
      if (hasLegacyReactApp) {
        const userId = window.gon?.current_user_id;
        const legacyRootEntrypoint = document.getElementById(
          userId ? 'react_app' : 'react-page'
        );

        if (window.location.pathname === '/sign_up') {
          import(/* webpackChunkName: "asyncSignUpApp" */ './SignUpApp').then(
            ({ default: SignUpApp }) => {
              ReactDOM.render(<SignUpApp />, legacyRootEntrypoint);
            }
          );
        } else if (window.location.pathname === '/ats_free') {
          import(/* webpackChunkName: "asyncSignUpApp" */ './SignUpApp').then(
            ({ default: SignUpApp }) => {
              ReactDOM.render(<SignUpApp isATSFree />, legacyRootEntrypoint);
            }
          );
        } else if (window.location.pathname === '/users/sign_in') {
          import(/* webpackChunkName: "signInApp" */ './SignInApp').then(
            ({ default: SignInApp }) => {
              ReactDOM.render(<SignInApp />, legacyRootEntrypoint);
            }
          );
        } else {
          import(
            /* webpackChunkName: "legacyRouteConfig" */ './modules/spa/beta/router/legacyRouteConfig'
          ).then(({ default: legacyRouteConfig }) => {
            import(/* webpackChunkName: "asyncApp" */ './App').then(
              ({ default: App }) => {
                ReactDOM.render(
                  <App routeConfig={legacyRouteConfig} />,
                  legacyRootEntrypoint
                );
              }
            );
          });
        }
      }
    };

    const loadedStates = ['complete', 'loaded', 'interactive'];

    if (loadedStates.indexOf(document.readyState) !== -1 && document.body) {
      injectRendering();
    } else {
      window.addEventListener('DOMContentLoaded', injectRendering, false);
    }
  }
};

const startApp = (localeCode: 'en-AU' | 'en-GB' | 'en-NZ') => {
  const setIntlStore = (intlObject: typeof FALLBACK_TRANSLATION) => {
    const intlReducer = (state = intlObject) => state;
    const store = createStore(combineReducers({ intl: intlReducer }));
    Intl.setStore(store);
  };

  if (localeCode === LOCALE_CODE.AU) {
    // We use FALLBACK_TRANSLATION for default language: apps/hr-web-app/buildPlugins/addDefaultTranslationMessagePlugin.ts
    setIntlStore(FALLBACK_TRANSLATION);
    initApp();
  } else if (localeCode === LOCALE_CODE.GB) {
    import('./packages/eh-locale/lang/en-GB.json').then(
      ({ default: gbLocaleConfig }) => {
        const localeConfig = merge(FALLBACK_TRANSLATION, gbLocaleConfig);
        setIntlStore(localeConfig);
        initApp();
      }
    );
  } else {
    getLocaleConfigFromCdn(localeCode).then(localeConfig => {
      setIntlStore(localeConfig);
      initApp();
    });
  }
};

addLocaleData(enLocaleData);
startApp(window.gon?.locale || LOCALE_CODE.AU);
