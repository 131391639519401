import startsWith from 'lodash/fp/startsWith';

import { getUserSpaApiPathFromLocation } from './path';
import ENV from './envs';

export const getFilePickerApiKey = () => ENV.REACT_APP_FILEPICKER_API_KEY;

export const getReCaptchaSiteKey = () =>
  ENV.REACT_APP_RECAPTCHA_SITE_KEY as string;

export const getReCaptchaV2SiteKey = () =>
  ENV.REACT_APP_RECAPTCHA_V2_SITE_KEY as string;

export const getVitallyToken = () => ENV.REACT_APP_VITALLY_TOKEN;

export const getLoginUrl = () => '/users/sign_in';

export const getScanVirusUrl = () => ENV.REACT_APP_SCAN_VIRUS_URL as string;

export const enabledScanningVirus = () =>
  ENV.REACT_APP_ENABLED_SCANNING_VIRUS === 'true';

export const getMainAppHost = () => ENV.REACT_APP_MAIN_APP_HOST;

export const getAuthUrl = () => [getMainAppHost(), 'auth'].join('/');

export const getAioAuthUrl = () => [getMainAppHost(), 'aio_auth'].join('/');

export const getContractApiHostForAdmin = () =>
  [getMainAppHost(), 'sap_api/v1'].join('/');

export const getContractApiHostForUser = ({
  omitOrgId,
}: {
  omitOrgId?: boolean;
} = {}) => `${getMainAppHost()}${getUserSpaApiPathFromLocation({ omitOrgId })}`;

export const getContractApiHost = ({
  omitOrgId,
}: { omitOrgId?: boolean } = {}) => {
  const pathName = window.location.pathname;
  if (startsWith('/admin')(pathName)) {
    return getContractApiHostForAdmin();
  }
  return getContractApiHostForUser({ omitOrgId });
};

export const adminApiHost = () => [getMainAppHost(), 'sap_api/v1'].join('/');

export const getFeatureFlagApiHost = () => ENV.REACT_APP_FEATURE_FLAG_API_HOST;

export const getFeatureGuideHost = () => ENV.REACT_APP_FEATURE_GUIDE_HOST;

export const getKPSyncServiceHost = () => ENV.REACT_APP_KP_SYNC_SERVICE_HOST;

export const getCobrandingHost = () => ENV.REACT_APP_COBRANDING_API_HOST;

export const getNotificationGatewayHost = () =>
  ENV.REACT_APP_NOTIFICATION_GATEWAY_HOST;

export const getMarketplaceHost = () => ENV.REACT_APP_MARKETPLACE_HOST;

export const getLifecycleHost = () => ENV.REACT_APP_LIFECYCLE_SERVICE_HOST;

export const getHeropayHost = () => ENV.REACT_APP_HEROPAY_HOST;

export const getHeroDollarsHost = () => ENV.REACT_APP_HERODOLLARS_HOST;

export const getInstapayMarketingHost = () =>
  ENV.REACT_APP_INSTAPAY_MARKETING_HOST;

export const getUrlTrackingHost = () => ENV.REACT_APP_URL_TRACKING_SERVICE_HOST;

export const getMarketplaceInspectletId = () =>
  ENV.REACT_APP_MARKETPLACE_INSPECTLET_ID;

// TODO: currently setting this first
export const getDefaultInspectletId = () =>
  ENV.REACT_APP_MARKETPLACE_INSPECTLET_ID;

export const getEhHRInspectletId = () => ENV.REACT_APP_EH_HR_INSPECTLET_ID;

export const getPlaygroundInspectletId = () =>
  ENV.REACT_APP_EH_PLAYGROUND_INSPECTLET_ID;

export const getCMSInspectletId = () => ENV.REACT_APP_EH_CMS_INSPECTLET_ID;

export const getCMSPdfEditorInspectletId = () =>
  ENV.REACT_APP_CMS_PDF_EDITOR_INSPECTLET_ID;

export const getB2bInspectletId = () => ENV.REACT_APP_EH_B2B_INSPECTLET_ID;

export const getAssetRegisterInspectletId = () =>
  ENV.REACT_APP_ASSET_REGISTER_INSPECTLET_ID;

export const getSecurityPermissionsInspectletId = () =>
  ENV.REACT_APP_SECURITY_PERMISSIONS_INSPECTLET_ID;

export const getFeedbackInspectletId = () =>
  ENV.REACT_APP_FEEDBACK_INSPECTLET_ID;

export const getOneOnOneInspectletId = () =>
  ENV.REACT_APP_ONEONONE_INSPECTLET_ID;

export const getAtsInspectletId = () => ENV.REACT_APP_EH_ATS_INSPECTLET_ID;

export const getLMSInspectletId = () => ENV.REACT_APP_LMS_INSPECTLET_ID;

export const getPayrollGuideInspectletId = () =>
  ENV.REACT_APP_EH_PAYROLL_GUIDE_INSPECTLET_ID;

export const getPayrollApiHost = () => ENV.REACT_APP_PAYROLL_API_HOST;

export const getDemoAccountFlowInspectletId = () =>
  ENV.REACT_APP_DEMO_ACCOUNT_FLOW_INSPECTLET;

export const getStartingGuidePageInspectletId = () =>
  ENV.REACT_APP_STARTING_GUIDE_PAGE_INSPECTLET;

export const getUrlSurveyServiceApiHost = () =>
  ENV.REACT_APP_URL_SURVEY_SERVICE_API_HOST;

export const getCommentServiceApiHost = () =>
  ENV.REACT_APP_COMMENT_SERVICE_API_HOST;

export const getAtsServiceApiHost = () => ENV.REACT_APP_ATS_SERVICE_API_HOST;

export const getCareerPageHost = () => ENV.REACT_APP_CAREER_PAGE_HOST;

export const getAccountVerificationServiceApiHost = () =>
  ENV.REACT_APP_ACCOUNT_VERIFICATION_SERVICE_API_HOST;

export const getSentryConfig = () => ENV.REACT_APP_SENTRY_CONFIG;

export const getAuditApiHost = () => ENV.REACT_APP_AUDIT_API_HOST;

export const getLegacyAuditApiHost = () => ENV.REACT_APP_LEGACY_AUDIT_API_HOST;

export const getGaUuid = () => ENV.REACT_APP_GA_UUID;

export const getPaySummaryApiHost = () => ENV.REACT_APP_PAY_SUMMARY_API_HOST;

export const cucumberMockEnabled = () =>
  ENV.REACT_APP_MOCK_FOR_CUCUMBER === 'true';

export const getAnnouncementApiHost = () => ENV.REACT_APP_ANNOUNCEMENT_API_HOST;

export const getDelaySessionTimeCheckConfig = () => 10 * 1000;

export const getNominationApiHost = () =>
  ENV.REACT_APP_NOMINATION_SERVICE_API_HOST;

export const getEmployeeMilestoneApiHost = () =>
  ENV.REACT_APP_EMPLOYEE_MILESTONE_SERVICE_API_HOST;

export const getHeroDollarPurchaseHost = () =>
  ENV.REACT_APP_HERODOLLAR_PURCHASE_HOST;

export const getSuperfundLookupServiceHost = () =>
  ENV.REACT_APP_SUPERFUND_LOOKUP_SERVICE_API_HOST;

export const getMobileAppDynamicLink = () =>
  ENV.REACT_APP_MOBILE_APP_DYNAMIC_LINK;

export const getExternalAuthApiHost = () =>
  ENV.REACT_APP_EXTERNAL_AUTH_API_HOST;

export const getLmsApiHost = () => ENV.REACT_APP_LMS_HOST;

export const getETFNDApiHost = () => ENV.REACT_APP_ETFND_API_HOST;

export const getMailServiceApiHost = () => ENV.REACT_APP_MAIL_SERVICE_API_HOST;

export const getSurveyApiHost = () => ENV.REACT_APP_SURVEY_HOST;

export const getOnboardingSurveyApiHost = () =>
  `${getSurveyApiHost()}/v1/admin/onboarding_surveys`;

export const getLmsPortalNameSuffix = () =>
  ENV.REACT_APP_LMS_PORTAL_NAME_SUFFIX;

export const getFileServiceApiHost = () => ENV.REACT_APP_FILE_SERVICE_API_HOST;

export const getMeetingManagementApiHost = () =>
  ENV.REACT_APP_MEETING_MANAGEMENT_SERVICE_API_HOST;

export const getCommentApiHost = () => ENV.REACT_APP_COMMENT_SERVICE_API_HOST;

export const getTurnOffIntentionSurvey = () =>
  ENV.REACT_APP_TURN_OFF_INTENTION === 'true';

export const getBillingImplementationAccess = () =>
  ENV.REACT_APP_BILLING_IMPLEMENTATION_ACCESS === 'true';

export const getRosteringApiHost = () => ENV.REACT_APP_ROSTERING_API_HOST;

export const getCESSurveyDelightedSurveyKey = () =>
  ENV.REACT_APP_CES_SURVEY_DELIGHTED_SURVEY_KEY;

export const getCESSurveyDelightedSurveyLocale = () =>
  ENV.REACT_APP_CES_SURVEY_DELIGHTED_SURVEY_LOCALE;

export const isUsingNewSetupTwofaModalUi = () =>
  ENV.REACT_APP_USE_NEW_SETUP_TWOFA_MODAL_UI === 'true';

export const getPayRunServiceApiHost = () =>
  ENV.REACT_APP_PAY_RUN_SERVICE_API_HOST;

export const getOnboardRemoteFieldEnabled = () =>
  ENV.REACT_APP_ONBOARD_REMOTE_FIELD_ENABLED === 'true';

export const getExpenseApiHost = () => ENV.REACT_APP_EXPENSE_API_HOST;

export const getPayrollReportApiHost = () =>
  ENV.REACT_APP_PAYROLL_REPORT_SERVICE_API_HOST;

export const getAppcuesSetupDashboardEnabled = () =>
  ENV.REACT_APP_ENABLED_APPCUES_SETUP_DASHBOARD === 'true';

export const getAppcuesStandardDashboardEnabled = () =>
  ENV.REACT_APP_ENABLED_APPCUES_STANDARD_DASHBOARD === 'true';

export const getAppcuesPremiumDashboardEnabled = () =>
  ENV.REACT_APP_ENABLED_APPCUES_PREMIUM_DASHBOARD === 'true';

export const getLocaleConfigHost = () => ENV.REACT_APP_LOCALE_CONFIG_HOST;

export const getInternalIntegrationsHost = () =>
  ENV.REACT_APP_INTERNAL_INTEGRATIONS_HOST;

export const getLocaleProjectId = () => ENV.REACT_APP_LOCALE_PROJECT_ID;

export const getAppcuesPlatinumDashboardEnabled = () =>
  ENV.REACT_APP_ENABLED_APPCUES_PLATINUM_DASHBOARD === 'true';

export const isEnabledRebranding = () =>
  ENV.REACT_APP_ENABLED_REBRANDING === 'true';

export const getDisabledEmployingEntityForPayrollFreemium = () =>
  ENV.REACT_APP_DISABLED_EMPLOYING_ENTITY_FOR_PAYROLL_FREEMIUM === 'true';

export const getFormApiHost = () => ENV.REACT_APP_FORM_HOST;

export const getEmploymentSchedulesApiHost = () =>
  ENV.REACT_APP_EMPLOYMENT_SCHEDULES_API_HOST;

export const getMixpanelTokenConfig = () => ENV.REACT_APP_MIXPANEL_TOKEN;

export const getConsentMarketingApiHost = () =>
  ENV.REACT_APP_CONSENT_MARKETING_HOST;

export const getUrlPerformanceEvaluationServiceApiHost = () =>
  ENV.REACT_APP_PERFORMANCE_EVALUATION_SERVICE_API_HOST;

export const getGoogleApiKey = () => ENV.REACT_APP_GOOGLE_API_KEY;

export const getReactionsHost = () => ENV.REACT_APP_REACTIONS_HOST;

const stripeKeysByCountry: Record<string, string> = {
  AU: ENV.REACT_APP_STRIPE_PUBLIC_KEY_AU,
  GB: ENV.REACT_APP_STRIPE_PUBLIC_KEY_GB,
};

export const getStripePublicKey = ({
  countryCode,
}: {
  countryCode: string;
}): string | null => stripeKeysByCountry[countryCode.toUpperCase()] ?? null;

export const isEnabledKeypay = () => ENV.REACT_APP_KEYPAY === 'true';

export const getEbfBffMobileHost = () => ENV.REACT_APP_EBF_BFF_MOBILE_HOST;

export const getEbfEhFinTechHost = () => ENV.REACT_APP_EBF_EH_FINTECH_HOST;

export const getSecureSapOutboundOrigin = () =>
  ENV.REACT_APP_SECURE_SAP_OUTBOUND_ORIGIN || '';

export const getSecureSapInboundOrigin = () =>
  ENV.REACT_APP_SECURE_SAP_INBOUND_ORIGIN || '';

export const getWorkflowWizardApiHost = () =>
  ENV.REACT_APP_WORKFLOW_WIZARD_API_HOST;

export const getWorkflowEngineApiHost = () =>
  ENV.REACT_APP_WORKFLOW_ENGINE_API_HOST;

export const getAccountProvisioningApiHost = () =>
  ENV.REACT_APP_ACCOUNT_PROVISIONING_API_HOST;

export const getReportServiceApiHost = () =>
  ENV.REACT_APP_REPORT_SERVICE_API_HOST;

export const getPublicURLHost = () => ENV.PUBLIC_URL;

export const getBingMapsServiceApiKey = () =>
  ENV.REACT_APP_BING_MAPS_SERVICE_API_KEY;

export const getTwofaMandated = () => ENV.REACT_APP_TWOFA_MANDATED;

export const getReactAppS3 = () => ENV.REACT_APP_S3;

export const isEnabledKPTheme = () => ENV.REACT_APP_ENABLED_KP_THEME === 'true';

export const getSidebarCachingWhitelist = () =>
  ENV.REACT_APP_SIDEBAR_CACHING_WHITELIST;

export const getFirebaseApiKey = () => ENV.REACT_APP_FIREBASE_APIKEY;

export const getFirebaseAuthDomain = () => ENV.REACT_APP_FIREBASE_AUTH_DOMAIN;

export const getFirebaseProjectId = () => ENV.REACT_APP_FIREBASE_PROJECT_ID;

export const getFirebaseStorageBucket = () =>
  ENV.REACT_APP_FIREBASE_STORAGE_BUCKET;

export const getFirebaseMessagingSenderId = () =>
  ENV.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;

export const getFirebaseAppId = () => ENV.REACT_APP_FIREBASE_APP_ID;

export const getNodeEnv = () => ENV.NODE_ENV;

export const getReactAppMockData = () => ENV.REACT_APP_MOCK_DATA;

export const getAioApiHost = (region?: string) => {
  const REGION = {
    AU: 'AU',
    UK: 'GB',
    SG: 'SG',
    MY: 'MY',
    NZ: 'NZ',
  };
  switch (region) {
    case REGION.AU:
      return ENV.REACT_APP_PAYROLL_AU_PRIVATE_API_HOST;
    case REGION.UK:
      return ENV.REACT_APP_PAYROLL_UK_PRIVATE_API_HOST;
    case REGION.NZ:
      return ENV.REACT_APP_PAYROLL_NZ_PRIVATE_API_HOST;
    case REGION.SG:
      return ENV.REACT_APP_PAYROLL_SG_PRIVATE_API_HOST;
    case REGION.MY:
      return ENV.REACT_APP_PAYROLL_MY_PRIVATE_API_HOST;
    default:
      return ENV.REACT_APP_PAYROLL_AU_PRIVATE_API_HOST;
  }
};

export const getEnableSmartMatchMarkAsShown = () =>
  ENV.REACT_APP_ENABLE_SMART_MATCH_MARK_AS_SHOWN === 'true';

export const getAwarePasswordPublicKey = () =>
  ENV.REACT_APP_AWARE_PASSWORD_PUBLIC_KEY || '';

export const getAwareEncryptedKeyId = () =>
  ENV.REACT_APP_AWARE_ENCRYPTED_KEY_ID || 'employmenthero';

export const getZendeskWidgetKey = (isSetupMode?: boolean) => {
  if (isSetupMode) {
    return (
      ENV.REACT_APP_ZENDESK_WIDGET_SETUP_MODE_KEY ||
      ENV.REACT_APP_ZENDESK_WIDGET_KEY
    );
  }
  return ENV.REACT_APP_ZENDESK_WIDGET_KEY;
};

export const getBrazeApiKey = () => ENV.REACT_APP_BRAZE_API_KEY || '';

export const getZendeskAISessionFieldId = () =>
  ENV.REACT_APP_ZENDESK_AI_SESSION_FIELD_ID || '';

export const getSalaryGuideServiceHost = () =>
  ENV.REACT_APP_SALARY_GUIDE_SERVICE_HOST || '';

export const getSmartMatchHubOccupationsListUrl = () =>
  ENV.REACT_APP_SM_HUB_OCCUPATIONS_LIST_HOST || '';

export const getSmartMatchHubLocationListUrl = () =>
  ENV.REACT_APP_SM_HUB_LOCATIONS_LIST_HOST || '';

export const getEmployeeVisionEmploymentRecordEnabled = () =>
  ENV.REACT_APP_EMPLOYEE_VISION_EMPLOYMENT_HISTORY_ENABLED === 'true';

export const getEmployeeVisionCertificationEnabled = () =>
  ENV.REACT_APP_EMPLOYEE_VISION_CERTIFICATION_ENABLED === 'true';

export const getEmployeeVisionManagementNotesEnabled = () =>
  ENV.REACT_APP_EMPLOYEE_VISION_MANAGEMENT_NOTES_ENABLED === 'true';

export const getZendeskWidgetDefaultKey = () =>
  ENV.REACT_APP_ZENDESK_WIDGET_DEFAULT_KEY || '';

export const getZendeskWidgetOnboardingKey = () =>
  ENV.REACT_APP_ZENDESK_WIDGET_ONBOARDING_KEY || '';

export const getZendeskWidgetSlice2Key = () =>
  ENV.REACT_APP_ZENDESK_WIDGET_SLICE_2_KEY || '';

export const getZendeskWidgetSupportedCountrySetupModeKey = () =>
  ENV.REACT_APP_ZENDESK_WIDGET_SUPPORTED_COUNTRY_SETUP_MODE_KEY || '';
